<template>
  <div class="page_wrapper">
    <div class="banner">
      <div class="banner-content">
        <img class="logo" src="@/assets/images/wx_pay_logo.png" />
        <div class="slogan">
          <span>成为微信支付商家</span>
          <p>微信支付服务商-中微信通代为提交</p>
        </div>
        <button class="pay-apply-btn" @click="handleApplyClick">
          立即开通
        </button>
      </div>
    </div>
    <div class="feature-box">
      <div class="feature-title">特色功能</div>
      <div class="feature-box-content">
        <div class="feature-box-content__item">
          <div class="item-icon">
            <img src="@/assets/images/shoukuanma.png" />
          </div>
          <div class="item-content">
            <div class="title">官方收款码</div>
            <div class="description">官方收款二维码，助力轻松经营第一步</div>
          </div>
        </div>
        <div class="feature-box-content__item">
          <div class="item-icon">
            <img src="@/assets/images/jingyingfenxi.png" />
          </div>
          <div class="item-content">
            <div class="title">经营分析</div>
            <div class="description">每日生成分析报表，清晰掌握生意数据</div>
          </div>
        </div>
        <div class="feature-box-content__item">
          <div class="item-icon">
            <img src="@/assets/images/huiyuan.png" />
          </div>
          <div class="item-content">
            <div class="title">会员</div>
            <div class="description">顾客一键变会员，积分抵现，提升忠诚度</div>
          </div>
        </div>
        <div class="feature-box-content__item">
          <div class="item-icon">
            <img src="@/assets/images/xinyongqia.png" />
          </div>
          <div class="item-content">
            <div class="title">支持信用卡付款</div>
            <div class="description">让顾客在门店支付时有更多选择</div>
          </div>
        </div>
      </div>
    </div>
    <div class="other-features">
      <div class="feature-title">其他功能</div>
      <div class="feature-list">
        <div class="feature-item">
          <div class="feature-icon">
            <img src="@/assets/images/wuliao.png" />
          </div>
          <span class="feature-name">官方免费物料</span>
        </div>
        <div class="feature-item">
          <div class="feature-icon">
            <img src="@/assets/images/qianbao.png" />
          </div>
          <span class="feature-name">官方补贴活动</span>
        </div>
        <div class="feature-item">
          <div class="feature-icon">
            <img src="@/assets/images/duodianguanli.png" />
          </div>
          <span class="feature-name">多门店管理</span>
        </div>
        <div class="feature-item">
          <div class="feature-icon">
            <img src="@/assets/images/shangjia.png" />
          </div>
          <span class="feature-name">商家名片</span>
        </div>
        <div class="feature-item">
          <div class="feature-icon">
            <img src="@/assets/images/yuyinbobaox.png" />
          </div>
          <span class="feature-name">收款语音播报</span>
        </div>
        <div class="feature-item">
          <div class="feature-icon">
            <img src="@/assets/images/posshoukuan.png" />
          </div>
          <span class="feature-name">收款工具</span>
        </div>
      </div>
    </div>
    <div class="apply-notice">
      <div class="feature-title">开通须知</div>
      <div class="notice-list">
        <div class="notice-item">
          <div class="notice-item-icon">
            T+1
          </div>
          <div class="notice-item-content">
            <div class="notice-item-title">资金T+1到账</div>
            <div class="notice-item-description">今日收款次日银行卡到账</div>
          </div>
        </div>
      </div>
    </div>
    <div class="pay-methods">
      <div class="feature-title">支付方式</div>
      <div class="pay-methods-list">
        <div class="pay-method-item">
          <div class="pay-method-icon">
            <img src="@/assets/images/gongzhonghao.png" />
          </div>
          <span class="pay-method-name">公众号支付</span>
        </div>
        <div class="pay-method-item">
          <div class="pay-method-icon">
            <img src="@/assets/images/saoma.png" />
          </div>
          <span class="pay-method-name">扫码支付</span>
        </div>
        <div class="pay-method-item">
          <div class="pay-method-icon">
            <img src="@/assets/images/appzhifu.png" />
          </div>
          <span class="pay-method-name">APP支付</span>
        </div>
        <div class="pay-method-item">
          <div class="pay-method-icon">
            <img src="@/assets/images/xinyongka.png" />
          </div>
          <span class="pay-method-name">刷卡支付</span>
        </div>
      </div>
    </div>
    <div class="question-block">
      <a
        class="question-link"
        href="https://support.qq.com/embed/app/7512"
        >我有疑问</a
      >
    </div>
    <div class="pay-footer">
      <button class="pay-apply-btn" @click="handleApplyClick">立即开通</button>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    handleApplyClick() {
      this.$router.push({
        name: "Apply"
      });
    }
  }
};
</script>
<style lang="less" scoped>
.pay-apply-btn {
  background: #fffccb;
  border-radius: 4px;
  min-width: 180px;
  border: 0;
  font-size: 15px;
  color: #a69352;
  padding: 10px;
}
.banner {
  width: 100%;
  height: 265px;
  background: #5aa16c;
  position: relative;
  background-image: url("../../assets/images/shoukuanji.png");
  background-position: center 20%;
  background-repeat: no-repeat;
  background-size: 153px;

  .banner-content {
    position: absolute;
    bottom: 27px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
  }

  .logo {
    width: 40px;
    height: 40px;
  }

  .slogan {
    margin-top: 18px;
    font-size: 16px;
    color: #ffffff;
    line-height: 22px;
    white-space: nowrap;

    p {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }

  .pay-apply-btn {
    margin-top: 18px;
  }
}

.feature-title {
  font-size: 17px;
  line-break: 24px;
  color: #0d0c0c;
  text-align: center;
  position: relative;

  &:after {
    width: 40px;
    height: 2px;
    display: inline-block;
    background: #5aa16c;
    content: "";
    position: absolute;
    bottom: -3px;
    left: calc(50% - 20px);
  }
}

.feature-box {
  margin-top: 45px;

  .feature-box-content {
    margin-top: 48px;
    padding: 0 35px;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .feature-box-content__item {
    display: inline-flex;
    align-items: center;

    &:not(:first-of-type) {
      margin-top: 30px;
    }

    .item-icon {
      width: 40px;
      height: 40px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .item-content {
      flex: 1;
      margin-left: 9px;

      .title {
        font-size: 17px;
        line-height: 17px;
        color: #0d0c0c;
      }

      .description {
        margin-top: 9px;
        font-size: 14px;
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}

.other-features {
  margin-top: 45px;

  .feature-list {
    margin-top: 43px;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    width: 300px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    .feature-item {
      display: inline-flex;
      flex-direction: column;
      align-items: center;

      &:nth-child(-n + 3) {
        margin-bottom: 38px;
      }

      .feature-icon {
        width: 40px;
        height: 40px;

        img {
          max-height: 100%;
          max-width: 100%;
          text-align: center;
        }
      }

      .feature-name {
        margin-top: 9px;
        font-size: 15px;
        color: #0d0c0c;
      }
    }
  }
}

.apply-notice {
  margin-top: 45px;

  .notice-list {
    display: inline-flex;
    flex-direction: column;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 43px;
  }

  .notice-item {
    display: inline-flex;
    align-items: center;

    .notice-item-icon {
      width: 60px;
      height: 60px;
      border: 1px solid #d3d3d3;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #5ea370;
      font-size: 17px;
      border-radius: 50%;
    }

    .notice-item-content {
      margin-left: 14px;

      .notice-item-title {
        font-size: 17px;
        line-height: 1;
        color: #0d0c0c;
      }
      .notice-item-description {
        margin-top: 9px;
        font-size: 14px;
        line-height: 1;
        color: rgba(0, 0, 0, 0.4);
      }
    }
  }
}

.pay-methods {
  margin-top: 48px;

  .pay-methods-list {
    margin-top: 53px;
    display: inline-flex;
    flex-direction: column;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
  }

  .pay-method-item {
    display: inline-flex;
    align-items: center;

    &:not(:first-of-type) {
      margin-top: 30px;
    }

    .pay-method-icon {
      width: 25px;
      height: 25px;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }

    .pay-method-name {
      margin-left: 12px;
    }
  }
}

.question-block {
  margin-top: 50px;
  height: 70px;
  background: rgba(0, 0, 0, 0.04);
  display: flex;
  align-items: center;
  justify-content: center;

  .question-link {
    font-size: 12px;
    color: #4a90e2;
  }
}

.pay-footer {
  height: 90px;
  background: #5aa16c;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>